const decCases = [2, 0, 1, 1, 1, 2];

export function decOfNum(n, titles) {
  const a = n % 100 > 4 && n % 100 < 20 ? 2 : decCases[Math.min(n % 10, 5)];
  return titles[a];
}

export function downloadFileHelper(resp) {
  if (resp.error) {
    throw new Error(`Ошибка: ${resp.error}`);
  }
  fetch(resp.link)
    .then(response => {
      if (!response.ok) {
        throw new Error(`Ошибка загрузки: ${response.statusText}`);
      }
      return response.blob();
    })
    .then(blob => {
      const urlParts = resp.link.split('/');
      const filename = urlParts[urlParts.length - 1] || 'file.pdf';
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    })
    .catch(err => console.error('Ошибка:', err));
}
